.topplayer {
  width: fit-content;
  padding: 3px 7px;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 2px;
  background-image: url(https://rklive.site/public/game/new/image/profile.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: drop-shadow(0px 0px 2px black);
}
.topplayer p {
  margin: 0px;
  font-size: 10px !important;
}

#hidden_info_here.users_here .container .body .users_box .box_r {
  align-items: center;
  background-color: initial;
  height: 70px;
  background-image: linear-gradient(
    rgba(179, 132, 201, 0.84),
    rgba(57, 31, 91, 0.84) 50%
  );
  border-radius: 2px;
  border-width: 0;
  box-shadow: rgba(57, 31, 91, 0.24) 0 2px 2px,
    rgba(179, 132, 201, 0.4) 0 8px 12px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: Quicksand, sans-serif;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.04em;
  line-height: 16px;
  margin: 0;
  text-align: center;
  text-decoration: none;
  text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px,
    rgba(255, 255, 255, 0.2) 0 0 12px, rgba(57, 31, 91, 0.6) 1px 1px 4px,
    rgba(57, 31, 91, 0.32) 4px 4px 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
}

.gamemain {
  background-image: url(https://rklive.site/public/game/new/image/background.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_right
  .icons_header_right_click_2
  img {
  width: 6rem;
  height: 6rem;
}
.reloadbtn {
  background-image: url(https://rklive.site/public/game/new/image/dashboard.png);
  background-size: contain;
  background-position: center;
  color: white;
  /* padding: 5px 20px; */
  border-radius: 10px;
  border: none;
  height: 30px;
  width: 100px;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: gold;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(89, 19, 63, 1) enrod;
}
#saven_winner .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  width: 100vw;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

#saven_winner .container .header_r {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: absolute;
}
#saven_winner .container .header_r .header_left,
#saven_winner .container .header_r .header_right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
#saven_winner .container .header_r .icons_header_right .header_right_icons_ul {
  position: absolute;
  margin-left: none;
  position: absolute;
  margin: 0 !important;
  padding: 0 !important;
}
#saven_winner
  .container
  .header_r
  .icons_header_right
  .header_right_icons_ul
  i {
  margin-top: 1rem !important;
}
#saven_winner
  .container
  .header_r
  i.fa-solid.fa-user-group.icons_d.this_is_users.active {
  -webkit-animation: this_is_users 0.2s ease forwards;
  animation: this_is_users 0.2s ease forwards;
}
#saven_winner .container .header_r i.icons_d {
  background-image: linear-gradient(45deg, #680267, #240024);
  padding: 0.5rem;
  border-radius: 50%;
  border: 3px groove #d5ac43;
  color: #c5c5c5;
  cursor: pointer;
  font-size: 2rem;
}
#saven_winner .container .body .body_middle .images {
  position: relative;
}
#saven_winner .container .body .body_middle .images .saven_winner_here {
  position: relative;
}
#saven_winner .container .body .body_middle .images img.spinner_while {
  position: absolute;
  height: 13.5rem;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(20deg);
}
#saven_winner .container .body .body_middle .images img {
  height: 22rem;
}
#saven_winner .container .body .body_middle .images #all_animation_foots {
  position: absolute;
  width: 15rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(359deg);
}
#saven_winner .container .body .body_middle .images #all_animation_foots span {
  height: 100%;
  width: 100%;
  position: absolute;
}

#saven_winner
  .container
  .body
  .body_middle
  .images
  #all_animation_foots
  .fruit1 {
  transform: rotate(40deg);
}

#saven_winner
  .container
  .body
  .body_middle
  .images
  #all_animation_foots
  .fruit2 {
  transform: rotate(80deg);
}

#saven_winner
  .container
  .body
  .body_middle
  .images
  #all_animation_foots
  .fruit3 {
  transform: rotate(120deg);
}

#saven_winner
  .container
  .body
  .body_middle
  .images
  #all_animation_foots
  .fruit4 {
  transform: rotate(160deg);
}

#saven_winner
  .container
  .body
  .body_middle
  .images
  #all_animation_foots
  .fruit5 {
  transform: rotate(200deg);
}

#saven_winner
  .container
  .body
  .body_middle
  .images
  #all_animation_foots
  .fruit6 {
  transform: rotate(240deg);
}

#saven_winner
  .container
  .body
  .body_middle
  .images
  #all_animation_foots
  .fruit7 {
  transform: rotate(280deg);
}

#saven_winner
  .container
  .body
  .body_middle
  .images
  #all_animation_foots
  .fruit8 {
  transform: rotate(320deg);
}

#saven_winner
  .container
  .body
  .body_middle
  .images
  #all_animation_foots
  .fruit9 {
  transform: rotate(360deg);
}

#saven_winner
  .container
  .body
  .body_middle
  .images
  #all_animation_foots
  span
  img {
  height: 2.5rem;
  width: 2.5rem;
}
#saven_winner .container .body .body_bottom {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: center;
}
/* #saven_winner .container .body .body_bottom .images {
    position: relative;
  } */

.birdright {
  position: relative;
  animation: slideAnimation 5s;
}
.birdleft {
  position: relative;
  animation: slideAnimation2 5s;
}

@keyframes slideAnimation {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes slideAnimation2 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.steam {
  position: absolute;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background-color: #fff;
  margin-top: -75px;
  margin-left: 75px;
  z-index: 0;
  opacity: 0;
}

#steam1 {
  -webkit-animation: steam1 4s ease-out infinite;
  animation: steam1 4s ease-out infinite;
}

#steam3 {
  -webkit-animation: steam1 4s ease-out 1s infinite;
  animation: steam1 4s ease-out 1s infinite;
}

@-webkit-keyframes steam1 {
  0% {
    transform: translateY(0) translateX(0) scale(0.25);
    opacity: 0.2;
  }
  100% {
    transform: translateY(-200px) translateX(-20px) scale(1);
    opacity: 0;
  }
}

.zoomeffect {
  animation: zoomeasffect 4s;
}
.zoomeffectleft {
  font-weight: bold;
  position: absolute;
  margin-left: -1px;
  margin-top: 0px;
  font-size: 18px;
}
.zoomeffectright {
  font-weight: bold;
  position: absolute;
  margin-left: -70px;
  margin-top: -40px;
  font-size: 18px;
}

@-webkit-keyframes zoomeasffect {
  0% {
    zoom: 100%;
  }
  100% {
    zoom: 0%;
  }
}

@keyframes steam1 {
  0% {
    transform: translateY(0) translateX(0) scale(0.25);
    opacity: 0.2;
  }
  100% {
    transform: translateY(-200px) translateX(-20px) scale(1);
    opacity: 0;
  }
}

#steam2 {
  -webkit-animation: steam2 4s ease-out 0.5s infinite;
  animation: steam2 4s ease-out 0.5s infinite;
}

#steam4 {
  -webkit-animation: steam2 4s ease-out 1.5s infinite;
  animation: steam2 4s ease-out 1.5s infinite;
}

@-webkit-keyframes steam2 {
  0% {
    transform: translateY(0) translateX(0) scale(0.25);
    opacity: 0.2;
  }
  100% {
    transform: translateY(-200px) translateX(20px) scale(1);
    opacity: 0;
  }
}

@keyframes steam2 {
  0% {
    transform: translateY(0) translateX(0) scale(0.25);
    opacity: 0.2;
  }
  100% {
    transform: translateY(-200px) translateX(20px) scale(1);
    opacity: 0;
  }
}
.header.clock_time_count_down {
  font-size: 12px !important;
  color: #000 !important;
  font-family: fantasy;
}
#saven_winner .container .body .body_bottom .images h2.header {
  transform: translate(-50%, -50%);
  text-align: center;
  margin-left: 16px;
  margin-top: -16px;
}
#saven_winner .container .footer {
  width: 100%;
}
#saven_winner .container .footer .footer_top {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-bottom: 30px;
}
#saven_winner .container .footer .footer_top .box_wrapper {
  height: 20rem;
  background: transparent;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border: none;
  padding: 0.5rem;
  background-image: url(https://rklive.site/public/game/new/image/board-01.png);
  border-radius: 7px;
  background-repeat: no-repeat;
  background-position: center;
}

#saven_winner .container .footer .footer_top .box_wrapper1 {
  background-image: url(../images/appleboard.png);
}

#saven_winner .container .footer .footer_top .box_wrapper2 {
  background-image: url(../images/lemonboard.png);
}

#saven_winner .container .footer .footer_top .box_wrapper3 {
  background-image: url(../images/board-01.png);
}
#saven_winner .container .body .body_bottom {
  margin-top: -23px;
  margin-left: -3px;
}
#saven_winner .container .body .body_bottom .images .clock {
  width: 2rem;
  filter: drop-shadow(0px 0px 1px black);
}
#saven_winner .container .footer .footer_top .box_wrapper .box_wrapper_header,
#saven_winner .container .footer .footer_top .box_wrapper .box_wrapper_footer {
  display: block;
  width: 100%;
  /* margin-top: 14px; */
}
.box_wrapper_footer h2 {
  margin-top: -20px;
}
.icons_header_right_click_4 {
  margin-bottom: 2px;
}
.icons_header_right_click_1 {
  margin-bottom: 2px;
}
.icons_header_right_click_3 {
  margin-bottom: 2px;
}
#saven_winner
  .container
  .footer
  .footer_top
  .box_wrapper
  .box_wrapper_header
  .header,
#saven_winner
  .container
  .footer
  .footer_top
  .box_wrapper
  .box_wrapper_footer
  .header {
  color: white;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 0px;
}

#saven_winner .container .footer .footer_top .box_wrapper .box_wrapper_body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}
#saven_winner .container .footer .footer_top .box_wrapper .box_wrapper_body h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 2.5rem;
  color: #d5ac43;
}
#saven_winner
  .container
  .footer
  .footer_top
  .box_wrapper
  .box_wrapper_body
  img {
  width: 2.5rem;
  height: 2.5rem;
}
#saven_winner
  .container
  .footer
  .footer_top
  .box_wrapper
  .box_wrapper_body
  img.coin_box1 {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-animation: coin_box_1_anime 1s ease forwards;
  animation: coin_box_1_anime 1s ease forwards;
}
#saven_winner
  .container
  .footer
  .footer_top
  .box_wrapper
  .box_wrapper_body
  img.coin_box2 {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-animation: coin_box_2_anime 1s ease forwards;
  animation: coin_box_2_anime 1s ease forwards;
}
#saven_winner
  .container
  .footer
  .footer_top
  .box_wrapper
  .box_wrapper_body
  img.coin_box3 {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  -webkit-animation: coin_box_3_anime 1s ease forwards;
  animation: coin_box_3_anime 1s ease forwards;
}
#saven_winner .container .footer .footer_middle {
  padding: 1.5rem;
  display: flex;
  align-items: end;
  justify-content: end;
}
#saven_winner .container .footer .footer_middle span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #d5ac43;
  gap: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}
#saven_winner .container .footer .footer_bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2.5rem;
  background-image: url(https://rklive.site/public/game/new/image/footerbar.png);
  background-size: 100% 100%;
  background-position: center;
  padding: 6px;
  filter: drop-shadow(0px 0px 10px black);
}
#saven_winner .container .footer .footer_bottom .footer_bottom_left {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_left
  .images
  img {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 9px;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right {
  padding: 0.3rem;
  width: 8rem;
  border-left: none;
  border-radius: 7px;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_top {
  margin-bottom: 0.5rem;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_top
  input {
  width: 100%;
  border-radius: 9px;
  border: none;
  outline: none;
  background: linear-gradient(45deg, #ff0000, #f59024);
  padding-left: 1rem;
  height: 1.4rem;
  font-size: 13px;
  padding: 10px;
  box-shadow: inset 3px 2px 9px rgba(0, 0, 0, 0.445);
  font-family: monospace;
  text-transform: uppercase;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_top
  input::-moz-placeholder {
  color: white;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_top
  input:-ms-input-placeholder {
  color: white;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_top
  input::placeholder {
  color: white;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(89, 19, 63, 1);
  border-radius: 9px;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_bottom
  input {
  border: none;
  outline: none;
  padding: 0.3rem;
  padding-left: 1rem;
  background: linear-gradient(45deg, #c317cc, #f52424);
  width: 100%;
  height: 2.4rem;
  box-shadow: inset 3px 2px 9px rgba(0, 0, 0, 0.445);
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_bottom
  input::-moz-placeholder {
  color: white;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_bottom
  input:-ms-input-placeholder {
  color: white;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_bottom
  input::placeholder {
  color: white;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_left
  .footer_bottom_left_right
  .footer_bottom_left_right_bottom
  img {
  height: 1.4rem;
}
#saven_winner .container .footer .footer_bottom .footer_bottom_right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
#saven_winner .container .footer .footer_bottom .footer_bottom_right .images {
  position: relative;
  filter: drop-shadow(0px 0px 2px black);
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_right
  .images
  img {
  width: 6rem;
  height: 6rem;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_right
  .images
  .animation {
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 5px solid rgba(255, 255, 255, 0.5607843137);
  -webkit-animation: this_is_btn 2s linear infinite;
  animation: this_is_btn 2s linear infinite;
}
#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_right
  .images
  .animation
  span {
  border-radius: 50%;
  position: absolute;
  height: 100%;
  width: 100%;
  color: #d5ac43;
  z-index: 1000;
}

#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_right
  .images
  .animation
  .animateCoin1 {
  transform: rotate(72deg);
}

#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_right
  .images
  .animation
  .animateCoin2 {
  transform: rotate(144deg);
}

#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_right
  .images
  .animation
  .animateCoin3 {
  transform: rotate(216deg);
}

#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_right
  .images
  .animation
  .animateCoin4 {
  transform: rotate(288deg);
}

#saven_winner
  .container
  .footer
  .footer_bottom
  .footer_bottom_right
  .images
  .animation
  .animateCoin5 {
  transform: rotate(360deg);
}

#hidden_info_here {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 100%;
}
#hidden_info_here1 {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 100%;
}
#hidden_info_here2 {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  width: 100%;
}
#hidden_info_here .container,#hidden_info_here2 .container,#hidden_info_here1 .container {
  height: auto;
  background: linear-gradient(4deg, rgb(89 19 63 / 61%), rgb(228 57 164));
  border: 1px solid #d5ac43;
  padding: 1.5rem;
  padding-top: 1.5rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 15px 100vh rgba(0, 0, 0, 0.445);
  border-radius: 7px;
  min-height: auto !important;
  overflow: visible !important;
}
#hidden_info_here .container .close_bar,#hidden_info_here1 .container .close_bar,#hidden_info_here2 .container .close_bar {
  position: absolute;
  font-size: 2.5rem;
  color: red;
  top: -15px;
  right: -15px;
}
#hidden_info_here .container .body ,#hidden_info_here .container .body1 ,#hidden_info_here2 .container .body{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#hidden_info_here .container .body .title {
  color: white;
  text-align: center;
}
#hidden_info_here .container .body .box_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
#hidden_info_here .container .body .box_wrapper .box_r {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
#hidden_info_here .container .body .box_wrapper .box_r .images img {
  width: 5rem;
  height: 5rem;
}
#hidden_info_here .container .body li {
  font-size: 1.5rem;
  color: white;
}
#hidden_info_here .container .body li .info {
  font-size: 1.8rem;
  font-weight: bold;
  color: #d5ac43;
}
#hidden_info_here .container .body li span.info_r {
  padding: 0 2rem;
  background: rgba(89, 19, 63, 1);
  border-radius: 5px;
}
#hidden_info_here .container .body .my_wining_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1rem;
  background: rgba(89, 19, 63, 1) enrod;
  padding: 1rem;
  border-radius: 5px;
}
#hidden_info_here .container .body .my_wining_info .right {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
#hidden_info_here .container .body .my_wining_info .images img {
  width: 5rem;
  height: 5rem;
  border-radius: 5px;
  border: 3px groove #d5ac43;
}
#hidden_info_here .music {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(
    rgba(179, 132, 201, 0.84),
    rgba(57, 31, 91, 0.84) 50%
  );
  border-radius: 2px;
  border-width: 0;
  box-shadow: rgba(57, 31, 91, 0.24) 0 2px 2px,
    rgba(179, 132, 201, 0.4) 0 8px 12px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-family: Quicksand, sans-serif;
  font-size: 10px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: 0.04em;
  line-height: 16px;
  margin: 0;
  text-align: center;
  text-decoration: none;
  text-shadow: rgba(255, 255, 255, 0.4) 0 0 4px,
    rgba(255, 255, 255, 0.2) 0 0 12px, rgba(57, 31, 91, 0.6) 1px 1px 4px,
    rgba(57, 31, 91, 0.32) 4px 4px 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  padding: 3px;
  /* padding-left: 2.5rem; */
  padding-right: 2.5rem;
  border-radius: 5px;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.445);
}
#hidden_info_here .music .header {
  color: white;
  font-weight: bold;
  color: #d5ac43;
  margin-right: 10px;
  margin-bottom: 0px;
}
#hidden_info_here input.music_checkbox {
  width: 3rem;
  height: 1.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: rgba(221, 221, 221, 0.8666666667);
  outline: none;
  border-radius: 20px;
  position: relative;
  transition: 0.5s;
}
#hidden_info_here input.music_checkbox:checked {
  background: rgba(89, 19, 63, 1);
  transition: 0.5s;
}
#hidden_info_here input.music_checkbox::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1.5rem;
  border-radius: 20px;
  background: white;
  box-shadow: 5px 5px 15px rgb(41, 41, 41);
  left: 0;
  transition: 0.5s;
}
#hidden_info_here input.music_checkbox:checked::before {
  left: auto;
  right: 0;
  transition: 0.5s;
}
body {
  cursor: cell;
}
#hidden_info_here.reward_here .container {
  height: 80vh;
}
#hidden_info_here.reward_here .container .close_bar {
  top: -15px;
  right: -15px;
}
#hidden_info_here.reward_here .container .topbodybar {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 0.2rem;
  flex-wrap: wrap;
  flex-direction: unset;
  border-radius: 7px;
  padding: 0rem;
}
#hidden_info_here.reward_here .container .topbodybar img {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  filter: drop-shadow(0px 0px 2px white);
}
#hidden_info_here.reward_here .container .body {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 0.2rem;
  flex-wrap: wrap;
  flex-direction: unset;
  border-radius: 7px;
  padding: 0rem;
}
#hidden_info_here.reward_here .container .body img {
  height: 2.5rem;
  width: 2.5rem;
  border: 2px groove #43d57c;
  border-radius: 50%;
}
#hidden_info_here.reward_here .container .result_pert {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: unset;
  gap: 0.2rem;
}
#hidden_info_here.reward_here .container .result_pert img {
  height: 3.5rem;
  width: 3.5rem;
  border: 3px groove #d5ac43;
  border-radius: 50%;
}

#hidden_info_here.users_here .container {
  height: 80vh;
  align-items: stretch;
  justify-content: start;
}
#hidden_info_here.users_here .container .close_bar {
  top: -15px;
  right: -15px;
}
#hidden_info_here.users_here .container .body {
  align-items: stretch;
  justify-content: start;
}
#hidden_info_here.users_here .container .body .users_box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  align-items: stretch;
  justify-content: stretch;
  gap: 1.5rem;
  flex-wrap: wrap;
}
#hidden_info_here.users_here .container .body .users_box .box_r {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;
  overflow: hidden;
}
#hidden_info_here.users_here .container .body .users_box .box_r img {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border: 1px solid gold;
}

#hidden_info_here .container::before {
  position: absolute;
  width: 50%;
  height: auto;
  top: 7px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(https://rklive.site/public/game/new/image/headbar.png);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  color: white;
  font-weight: bold;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  line-height: 60px;
}

.Winner_Here .container img.Winner_hat_1,
.Winner_Here .container img.Winner_hat_2 {
  width: 30%;
  position: absolute;
  top: -10%;
}

.Winner_Here .container img.Winner_hat_1 {
  left: -7%;
}

.Winner_Here .container img.Winner_hat_2 {
  right: -7%;
}

.Server_Issue .container::before {
  content: "Server Connecting..";
}
.Waiting_Here .container::before {
  content: "Attention";
}
.User_Inactive .container::before {
  content: "Attention";
}

.Winner_Here .container::before {
  content: "Good Luck";
}

.Settings_Here .container::before {
  content: "Settings";
}

.Rules_here .container::before {
  content: "History";
}

#hidden_info_here.reward_here .container::before {
  content: "Reward Result";
  top: 0%;
}
/* .body_middle{
    margin-top: 50%;
  } */
@media (max-width: 325px) {
  #saven_winner .container .footer .footer_bottom {
    zoom: 68% !important;
  }
}
#saven_winner .container .footer .footer_top .box_wrapper {
  filter: drop-shadow(0px 0px 1px black);
}
#saven_winner .container .body .body_middle .images img {
  filter: drop-shadow(0px 0px 7px black);
}

img.setng {
  filter: drop-shadow(0px 0px 1px black);
}

@media (max-width: 525px) {
  #saven_winner .container .body {
    /* height: 12rem; */
    transition: 1s;
  }
  #saven_winner .container .footer .footer_top .box_wrapper {
    zoom: 120%;
  }
  #saven_winner
    .container
    .footer
    .footer_bottom
    .footer_bottom_right
    .icons_header_right_click_2
    img {
    width: 3.5rem;
    height: 3.5rem;
  }
  .setng {
    width: 30px !important;
  }
  #saven_winner .container .body .body_middle .images img {
    height: 13rem;
  }
  #saven_winner .container .footer .footer_top .box_wrapper .box_wrapper_header,
  #saven_winner
    .container
    .footer
    .footer_top
    .box_wrapper
    .box_wrapper_footer {
    margin-top: 0px;
  }
  .box_wrapper_footer h2 {
    margin-top: 8px;
  }

  #saven_winner .container .body .body_middle .images img.spinner_while {
    height: 10rem;
  }
  #saven_winner .container .body .body_middle .images #all_animation_foots {
    width: 9.4rem;
  }
  #saven_winner
    .container
    .body
    .body_middle
    .images
    #all_animation_foots
    span
    img {
    height: 1.5rem;
    width: 1.5rem;
  }
  #saven_winner .container .footer .footer_top .box_wrapper {
    height: 5.5rem;
  }
  #saven_winner
    .container
    .footer
    .footer_top
    .box_wrapper
    .box_wrapper_body
    img {
    height: 2rem;
    width: 2rem;
  }
  #saven_winner
    .container
    .footer
    .footer_bottom
    .footer_bottom_right
    .images
    img,
  #saven_winner
    .container
    .footer
    .footer_bottom
    .footer_bottom_right
    .images
    .animation {
    height: 3rem;
    width: 3rem;
  }
  #saven_winner .container .footer .footer_bottom {
    margin-top: 0px;
    zoom: 85%;
  }
  #saven_winner
    .container
    .footer
    .footer_top
    .box_wrapper
    .box_wrapper_body
    img.coin_box1,
  #saven_winner
    .container
    .footer
    .footer_top
    .box_wrapper
    .box_wrapper_body
    img.coin_box2,
  #saven_winner
    .container
    .footer
    .footer_top
    .box_wrapper
    .box_wrapper_body
    img.coin_box3 {
    height: 1.5rem;
    width: 1.5rem;
  }
  #saven_winner
    .container
    .footer
    .footer_top
    .box_wrapper
    .box_wrapper_body
    h2 {
    font-size: 1.5rem;
  }
  #hidden_info_here .container .body .title {
    margin-bottom: 0;
    font-size: 1.2rem;
  }
  #hidden_info_here .container .body .box_wrapper .box_r .images img,
  #hidden_info_here .container .body .my_wining_info .images img {
    width: 4rem;
    height: 4rem;
  }
  #hidden_info_here .container .body li .info {
    font-size: 1.2rem;
  }
  #hidden_info_here .container .body li span.info_r {
    font-size: 1.2rem;
  }
  #hidden_info_here .container .body .my_wining_info {
    padding: 5px;
    margin-top: 5px;
  }
  #saven_winner
    .container
    .footer
    .footer_bottom
    .footer_bottom_right
    .images
    .animation {
    border: 2px solid #adb5bd;
  }
}
@-webkit-keyframes this_is_btn {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes this_is_btn {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
/*
  |----------------
  |  snipper roted
  |----------------
  */
@-webkit-keyframes this_is_users {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@keyframes this_is_users {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@-webkit-keyframes coin_box_1_anime {
  0% {
    left: 1500%;
    top: -200%;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@keyframes coin_box_1_anime {
  0% {
    left: 1500%;
    top: -200%;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@-webkit-keyframes coin_box_2_anime {
  0% {
    left: 850%;
    top: -200%;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@keyframes coin_box_2_anime {
  0% {
    left: 850%;
    top: -200%;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@-webkit-keyframes coin_box_3_anime {
  0% {
    left: 286%;
    top: -200%;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@keyframes coin_box_3_anime {
  0% {
    left: 286%;
    top: -200%;
  }
  100% {
    top: 0;
    left: 0;
  }
}
@-webkit-keyframes box_animation_apple {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.06);
  }
  10% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.06);
  }
  20% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.06);
  }
  30% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.06);
  }
  40% {
    transform: scale(1);
  }
  45% {
    transform: scale(1.06);
  }
  50% {
    transform: scale(1);
  }
  55% {
    transform: scale(1.06);
  }
  60% {
    transform: scale(1);
  }
  65% {
    transform: scale(1.06);
  }
  70% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.06);
  }
  80% {
    transform: scale(1);
  }
  85% {
    transform: scale(1.06);
  }
  90% {
    transform: scale(1);
  }
  95% {
    transform: scale(1.06);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes box_animation_apple {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.06);
  }
  10% {
    transform: scale(1);
  }
  15% {
    transform: scale(1.06);
  }
  20% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.06);
  }
  30% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.06);
  }
  40% {
    transform: scale(1);
  }
  45% {
    transform: scale(1.06);
  }
  50% {
    transform: scale(1);
  }
  55% {
    transform: scale(1.06);
  }
  60% {
    transform: scale(1);
  }
  65% {
    transform: scale(1.06);
  }
  70% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.06);
  }
  80% {
    transform: scale(1);
  }
  85% {
    transform: scale(1.06);
  }
  90% {
    transform: scale(1);
  }
  95% {
    transform: scale(1.06);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes rotet_while_1 {
  0% {
    transform: translate(-50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1460deg);
  }
}
@keyframes rotet_while_1 {
  0% {
    transform: translate(-50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1460deg);
  }
}
@-webkit-keyframes rotet_while_1_fruits {
  0% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1445deg);
  }
}
@keyframes rotet_while_1_fruits {
  0% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1445deg);
  }
}
@-webkit-keyframes rotet_while_2 {
  0% {
    transform: translate(-50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1537deg);
  }
}
@keyframes rotet_while_2 {
  0% {
    transform: translate(-50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1537deg);
  }
}
@-webkit-keyframes rotet_while_2_fruits {
  0% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1524deg);
  }
}
@keyframes rotet_while_2_fruits {
  0% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1524deg);
  }
}
@-webkit-keyframes rotet_while_3 {
  0% {
    transform: translate(-50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1692deg);
  }
}
@keyframes rotet_while_3 {
  0% {
    transform: translate(-50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1692deg);
  }
}
@-webkit-keyframes rotet_while_3_fruits {
  0% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1678deg);
  }
}
@keyframes rotet_while_3_fruits {
  0% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1678deg);
  }
}
@-webkit-keyframes rotet_while_4 {
  0% {
    transform: translate(-50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1501deg);
  }
}
@keyframes rotet_while_4 {
  0% {
    transform: translate(-50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1501deg);
  }
}
@-webkit-keyframes rotet_while_4_fruits {
  0% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1482deg);
  }
}
@keyframes rotet_while_4_fruits {
  0% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1482deg);
  }
}
@-webkit-keyframes saven_winner {
  0% {
    transform: translate(-50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1618deg);
  }
}
@keyframes saven_winner {
  0% {
    transform: translate(-50%, -50%) rotate(20deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1618deg);
  }
}
@-webkit-keyframes saven_winner_fruits {
  0% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1605deg);
  }
}
@keyframes saven_winner_fruits {
  0% {
    transform: translate(-50%, -50%) rotate(5deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(1605deg);
  }
}

/* Loader */
.loading-container {
  width: 100%;
  text-align: center;
  color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(
    circle farthest-corner at 10% 20%,
    rgba(89, 19, 63, 1) 0.1%,
    rgba(89, 19, 63, 1) enrod 94.2%
  );
  background-size: 100%;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  position: absolute;
  z-index: 999999999;
}
.loading-container:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #fff;
  bottom: 45%;
  left: 0;
  border-radius: 10px;
  animation: movingLine 2.4s infinite ease-in-out;
}

@keyframes movingLine {
  0% {
    opacity: 0;
    width: 0;
  }

  33.3%,
  66% {
    opacity: 0.8;
    width: 100%;
  }

  85% {
    width: 0;
    left: initial;
    right: 0;
    opacity: 1;
  }

  100% {
    opacity: 0;
    width: 0;
  }
}

.loading-text {
  font-size: 5vw;
  line-height: 64px;
  letter-spacing: 10px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-evenly;
}
.loading-text span {
  animation: moveLetters 2.4s infinite ease-in-out;
  transform: translatex(0);
  position: relative;
  display: inline-block;
  opacity: 0;
  text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
}

@for $i from 1 through 7 {
  .loading-text span:nth-child(#{$i}) {
    animation-delay: $i * 0.1s;
  }
}

@keyframes moveLetters {
  0% {
    transform: translateX(-15vw);
    opacity: 0;
  }

  33.3%,
  66% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    transform: translateX(15vw);
    opacity: 0;
  }
}

/* loder end */

@media screen and (min-height: 400px) {
  @-webkit-keyframes coin_box_1_anime {
    0% {
      right: 0%;
      top: 0%;
    }
    100% {
      top: 0;
      left: 0;
    }
  }

  @keyframes coin_box_1_anime {
    0% {
      right: 0%;
      top: 0%;
    }
    100% {
      top: 0;
      left: 0;
    }
  }

  @-webkit-keyframes coin_box_2_anime {
    0% {
      left: 0%;
      top: 0%;
    }
    100% {
      top: 0;
      left: 0;
    }
  }

  @keyframes coin_box_2_anime {
    0% {
      left: 0%;
      top: 0%;
    }
    100% {
      top: 0;
      left: 0;
    }
  }

  @-webkit-keyframes coin_box_3_anime {
    0% {
      left: 0%;
      top: 0%;
    }
    100% {
      top: 0;
      left: 0;
    }
  }

  @keyframes coin_box_3_anime {
    0% {
      left: 0%;
      top: 0%;
    }
    100% {
      top: 0;
      left: 0;
    }
  }
}

.closeDialog {
  position: fixed;
  top: 38px;
  right: -12px;
  border-radius: 50%;
  border: none;
}

.box_wrapper_body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.bet_images {
  width: 30px;
  height: 30px;
  position: absolute;
  object-fit: contain;
  z-index: 10;
  transition: all 0.3s ease;
  filter: none; /* Remove filter if it affects visibility */
  animation: auto ease 0s 1 normal none running none;
  top: 50%;
  bottom: 20%;
}



.disabled {
  pointer-events: none; /* Prevents interaction */
  opacity: 0.5; /* Makes the element appear dimmed */
  cursor: not-allowed; /* Indicates it's disabled */
}

;

@media (max-width: 768px) {
  table {
    width: 100%;
    font-size: 12px; /* Adjust font size for readability */
    border-collapse: collapse;
    margin: 10px 0; /* Add margin to the table for spacing */
    color: white!important;
  }

  thead {
    background-color: #f2f2f2;
    color: white!important;

  }

  th, td {
    padding: 10px; /* Increased padding for better spacing */
    text-align: left;
    font-size: 12px;
    border: 1px solid #ddd; /* Add a border between cells */
    
  }

  th {
    font-size: 14px;
    background-color: #f8f8f8;
    color: #333;
  }

  .winner-Box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .winner-number img {
    width: 20px;
    height: 20px;
  }

  /* Handling when there is no history data */
  .not-history {
    text-align: center;
    font-size: 14px;
    color: #888;
    padding: 10px 0; /* Add some padding for spacing */
  }

  /* Add scrollable container for the table with max height */
  .model-body {
    max-height: 350px;  /* Set maximum height */
    overflow-y: auto;   /* Enable vertical scrolling */
    margin-bottom: 20px; /* Add space below the history table */
  }
}

@media (max-width: 480px) {
  table {
    font-size: 10px; /* Further reduce font size for very small screens */
  }

  th, td {
    padding: 3px; /* Adjust padding for smaller screens */
  }

  .winner-number img {
    width: 15px;
    height: 15px; /* Resize images further for very small screens */
  }
}


#hidden_info_here .historyDialog {
  height: 100% !important; /* Ensure container uses the full height available */
  background: linear-gradient(4deg, rgb(89 19 63 / 61%), rgb(228 57 164)) !important;
  padding: .5rem !important;
  padding-top: .8rem !important;
  position: relative !important;
  display: flex !important;
  align-items: flex-start !important; /* Ensures items align at the top */
  justify-content: flex-start !important;
  box-shadow: 5px 5px 15px 100vh rgba(0, 0, 0, 0.445) !important;
  border-radius: 7px !important;
  min-height: 0 !important; /* Override the min-height being auto */
  width: 100% !important;
  overflow: hidden !important; /* Hide overflowing content */
  border: none !important;
}

#hidden_info_here .historyDialog .body {
  display: flex !important;
  flex-direction: column !important; /* Ensures the content stacks vertically */
  align-items: flex-start !important;
  justify-content: flex-start !important;
  max-height: 450px !important; /* Set max height to restrict the body content */
  overflow-y: auto !important; /* Enable vertical scrolling */
  width: 100% !important;
}

@media (max-width: 525px) {
  #hidden_info_here .historyDialog .body {
      height: 12rem !important; /* Optional: Set specific height for smaller screens */
  }
}



.closeSetting{

  position: fixed;
 top: -12px;
 right: -12px;
 border-radius: 50%;
 border: none;
}   


table{
  color: white !important;
}