* {
  padding: 0px;
  margin: 0px;
  user-select: none !important;
}
img {
  pointer-events: none !important;
}
.row {
  padding: 0px !important;
  margin: 0px !important;
}

body {
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

.text-danger {
  color: #ebc400 !important;
}
.text-success {
  color: #0bb568 !important;
}
.zoomeffectleft {
  font-weight: 700;
  position: absolute;
  margin-left: -1px;
  margin-top: 0px;
  font-size: 15px;
}
.zoomeffectright {
  font-weight: 700;
  position: absolute;
  margin-left: -81px;
  margin-top: 0px;
  font-size: 15px;
}

ul li{
  list-style-type: none;
}



