.winner-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10;
  animation: fadeIn 0.5s ease-in;
}

.winner-content {
  text-align: center;
  padding: 1rem;
  border-radius: 20px;
  animation: scaleIn 0.5s ease-out;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  max-width: 95%;
  margin: 0 auto;
}

.winner-container {
  position: relative;
  flex: 0 0 auto;
  width: 140px;
  padding: 1rem;
  background: #ffffff;
  border-radius: 12px;
  border: 3px solid transparent;
  box-shadow: 0 0 20px rgba(255, 215, 0, 0.2),
              0 8px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
}

.winner-position {
  position: absolute;
  top: -12px;
  font-size: 1.8rem;
  filter: drop-shadow(0 2px 4px rgba(0,0,0,0.2));
}

.winner-image img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid gold;
  animation: pulse 2s infinite;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.2);
  object-fit: cover;
}

.winner-name {
  font-size: 0.8rem;
  margin: 0;
  color: #333;
  font-weight: bold;
}

.winner-winnings {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: -0.3rem;
}

.winner-text {
  font-size: 0.8rem;
  margin: 0;
  color: #666;
}

.celebration-container {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  animation: floatUpDown 2s ease-in-out infinite;
}

.trophy-animation {
  font-size: 1.2rem;
  color: gold;
}

.confetti-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 11;
}

.confetti {
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: firecracker 1.5s ease-out forwards;
}

@keyframes firecracker {
  0% {
    transform: translate(-50%, 0) scale(0);
    opacity: 1;
  }
  20% {
    transform: translate(var(--x, 0), var(--y, 0)) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(
      calc(var(--x, 0) * 2),
      calc(var(--y, 0) * 2)
    ) scale(0.5);
    opacity: 0;
  }
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0.8); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .winner-content {
    gap: 1rem;
  }

  .winner-container {
    width: 120px;
    padding: 0.8rem;
  }

  .winner-image img {
    width: 50px;
    height: 50px;
  }

  .winner-winnings {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .winner-content {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5rem;
    padding: 0.5rem;
    max-width: 100%;
  }

  /* Second place (left side) */
  .winner-container:nth-child(1) {
    width: 110px;
    padding: 0.8rem;
    transform: scale(0.95);
    z-index: 1;
    margin-right: 0;
  }

  /* First place (middle) */
  .winner-container:nth-child(2) {
    width: 130px;
    padding: 1rem;
    transform: scale(1.05);
    z-index: 2;
    border-width: 3px;
  }

  /* Third place (right side) */
  .winner-container:nth-child(3) {
    width: 110px;
    padding: 0.8rem;
    transform: scale(0.95);
    z-index: 1;
    margin-left: 0;
  }

  /* Adjusting images for each position */
  .winner-container:nth-child(1) .winner-image img,
  .winner-container:nth-child(3) .winner-image img {
    width: 50px;
    height: 50px;
    border-width: 2px;
  }

  /* Adjusting text sizes */
  .winner-container:nth-child(1) .winner-name,
  .winner-container:nth-child(3) .winner-name {
    font-size: 0.9rem;
  }

  .winner-container:nth-child(1) .winner-winnings,
  .winner-container:nth-child(3) .winner-winnings {
    font-size: 0.9rem;
  }

  /* Trophy position adjustments */
  .winner-position {
    top: -10px;
    font-size: 1.4rem;
  }

  .winner-container:nth-child(2) .winner-position {
    top: -12px;
    font-size: 1.6rem;
  }

  /* Congratulations text */
  .winner-text {
    font-size: 0.75rem;
  }

  .winner-container:nth-child(2) .winner-text {
    font-size: 0.85rem;
  }
}

/* Add hover effect */
.winner-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 25px rgba(255, 215, 0, 0.3),
              0 10px 20px rgba(0, 0, 0, 0.15);
}

@keyframes floatUpDown {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.no-winner-container {
  background: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: bounceIn 0.6s ease-out;
}

.sad-emoji {
  font-size: 4rem;
  margin-bottom: 1rem;
  animation: shake 1s ease-in-out infinite;
}

.no-winner-text {
  font-size: 1.8rem;
  color: #333;
  margin: 0.5rem 0;
}

.no-winner-subtext {
  font-size: 1.2rem;
  color: #666;
  margin: 0;
}

@keyframes bounceIn {
  0% { transform: scale(0.3); opacity: 0; }
  50% { transform: scale(1.05); }
  70% { transform: scale(0.9); }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px) rotate(-5deg); }
  75% { transform: translateX(5px) rotate(5deg); }
}

@media screen and (max-width: 360px) {
  .winner-content {
    gap: 0.3rem;
  }

  .winner-container:nth-child(1),
  .winner-container:nth-child(3) {
    width: 100px;
    transform: scale(0.9);
  }

  .winner-container:nth-child(2) {
    width: 120px;
    transform: scale(1);
  }
}
