/* stylelint-disable declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix */

/* Reboot - Normalization of HTML elements */

/* Document */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Root */
:root {
  font-size: 16px;
}

/* Body */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Horizontal Rule */
hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.6;
}

hr:not([size]) {
  height: 1px;
}

/* Headings */
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
}

/* Paragraphs */
p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Abbreviations */
abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

/* Address */
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

/* Lists */
ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

/* Blockquote */
blockquote {
  margin: 0 0 1rem;
}

/* Strong */
b, strong {
  font-weight: bolder;
}

/* Small */
small {
  font-size: 80%;
}

/* Mark */
mark {
  padding: 0.2em;
  background-color: #ff0;
}

/* Sub and Sup */
sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Links */
a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

/* Code */
pre, code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

code {
  color: #e83e8c;
}

/* Figures */
figure {
  margin: 0 0 1rem;
}

/* Images */
img, svg {
  vertical-align: middle;
}

/* Tables */
table {
  caption-side: bottom;
  border-collapse: collapse;
}

th {
  font-weight: bold;
  text-align: left;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

/* Forms */
label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select:disabled {
  opacity: 1;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

/* Output */
output {
  display: inline-block;
}

/* Iframe */
iframe {
  border: 0;
}

/* Summary */
summary {
  display: list-item;
  cursor: pointer;
}

/* Progress */
progress {
  vertical-align: baseline;
}

/* Hidden attribute */
[hidden] {
  display: none !important;
}
